<template>
    <v-container >
        <v-row>
            <v-col>
                <h1 class="blue-grey--text text--darken-2 gradient">{{title}}</h1>
            </v-col>
            <v-col v-if="showConfig" class="ma-0 pa-0">
                <v-row >
                    <v-spacer />
                    <v-btn @click="showConfigDialog=true" class="ma-0 mt-1 pa-0 mr-2 secondary--text" small fab text>
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-divider />

        <v-row>
            <v-col>
                <slot name="content"/>
            </v-col>
        </v-row>

        <v-dialog max-width="800" v-model="showConfigDialog">
            <v-card >
                <v-card-title>
                    {{configTitle}}
                </v-card-title>
                <v-card-text>
                    
                <slot name="dashboard-config" />
                </v-card-text>
            </v-card>

        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name:"Registrations",
    props:{
        title:{type:String, default:"Dashboard Titel"},
        configTitle:{type:String, default:"Einstellungen Titel"},
        showConfig:Boolean,
        app:Object
    },
    data(){
        return {
            showConfigDialog:false,
        }
    }
}
</script>

<style scoped>
    .gradient{
        background: linear-gradient(90deg, rgb(122, 151, 165) 5%, rgba(38,50,56,1) 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>