<template>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title>
                    Stammdaten

                    <v-row class="pr-3">
                        <v-spacer/>
                        <v-btn :disabled="editedData !== undefined" class="primary" fab x-small elevation="2" @click="editDataSet()"> <v-icon>mdi-account-plus</v-icon> </v-btn>
                    </v-row>
                </v-card-title>

                <v-divider/>

                <v-card-text><v-expand-transition>
                        <v-row v-if="editedData !== undefined && !editedId">
                            <people-form-editor
                                ref="PeopleFormEditor"
                                :people-manager="this"
                            />
                        </v-row>
                    </v-expand-transition>

                    <people-table
                        ref="PeopleTable"
                        :dashboard="dashboard"
                        :people-manager="peopleManager"
                        :type="type"
                    />

                    
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import PeopleFormEditor from '@/components/PeopleFormEditor.vue';
import PeopleTable from '@/components/PeopleTable.vue';

export default {
    name:"PeopleManager",
    created(){
        this.loadPeopleData();
        
    },
    components:{
        PeopleFormEditor,
        PeopleTable,
    },
    props:{
        dashboard:Object,
        title:{type:String, default:"Mitarbeiter"},
        type:{type:String, default:"employees"}
    },
    data(){
        return{
            peopleManager:this,
            editedData:undefined,
            editedId:undefined,
            peopleData:undefined,
            peopleDataComplete:undefined,
            peopleSearch:'',
            peopleSelectedRows:[],
            peopleFilter:[],
            loadingData:false,
            propertyTypes:{},
            propertyIcons:{},
        }
    },
    methods:{
        async editDataSet(id){
            let loadedData = undefined;
            this.editedId = id;
            if(id){
                loadedData = await this.loadDataSet(id);
            }
            let editedData = [];
            for(let prop of this.dashboard.campData[this.type].props){
                let data = {};
                data.key = prop.key;
                data.type = prop.type;
                data.icon = prop.icon;
                data.icon_color = prop.icon_color;
                data.options = prop.options;
                if(prop.value){
                    data.value = prop.value;   
                }else{
                    if(prop.type === "string"){
                        data.value = "";
                    }else if(prop.type === "number"){
                        data.value = 0;
                    }else if(prop.type === "selection"){
                        data.value = "";
                    }
                }
            
                // if person data is found, use it instead of empty values
                if (loadedData){
                    for(let p of loadedData.data){
                        if (p.key === data.key){
                            data.value = p.value;
                        }
                    }
                }
                editedData.push(data);
            }
            this.editedData = editedData;
            this.$nextTick(()=>{
                if(!id){
                    this.$refs.PeopleFormEditor.title = "Neuer "+this.title;
                }
            })
            
        },

        loadDataSet(id){
            let url = this.dashboard.app.restUrl+"/records/"+this.type+"/"+id;
            return new Promise(resolve => {
                this.$axios.get(url, {withCredentials:true})
                .then((result)=>{
                    if(result.request.status === 200){
                        resolve(result.data);
                    }
                })
                .catch(()=>{
                    resolve(undefined);
                })
            });
        },  

        loadPeopleData(){
            if(this.dashboard.activeCamp){
                this.loadingData = true;
                let url = this.dashboard.app.restUrl+"/records/"+this.type+"?filter=camp,eq,"+this.dashboard.activeCamp;
                this.$axios(url, {withCredentials:true})
                .then((result)=>{
                    this.loadingData = false;
                    if(result.request.status === 200){
                        this.peopleSelectedRows = [];
                        this.peopleData = [];
                        this.peopleDataComplete = [];
                        if(result.data && result.data.records){
                            for(let records of result.data.records){
                                let data = {};
                                let dataComplete = {};
                                data.id = records.id;
                                dataComplete.id = records.id;
                                for (let prop of this.dashboard.campData[this.type].props){
                                    this.propertyTypes[prop.key] = prop.type;
                                    if(prop.icon){
                                        this.propertyIcons[prop.key] = prop.icon;
                                    }
                                    // load data that is set to be visible in table
                                    if(prop.visible){
                                        data[prop.key] = undefined;
                                        for(let p of records.data){
                                            if (p.key === prop.key){
                                                data[prop.key] = p.value;
                                            }
                                            dataComplete[prop.key] = p.value;
                                        }
                                    }

                                    // load complete data for search and filter queries
                                    dataComplete[prop.key] = undefined;
                                    for(let p of records.data){
                                        if (p.key === prop.key){
                                            dataComplete[prop.key] = p.value;
                                        }
                                    }
                                }
                                this.peopleData.push(data);
                                this.peopleDataComplete.push(dataComplete);
                            }
                        }
                    }
                });
            }

        },
        saveEdit(){
            var data = {data:[], camp:this.dashboard.activeCamp};
            for(let prop of this.editedData){
                if(prop.type !== "separator"){
                    data.data.push({key:prop.key, value: prop.value});
                }
            }

            if(this.editedId){
                let url = this.dashboard.app.restUrl+"/records/"+this.type+"/"+this.editedId;
                this.$axios.put(url, data, {withCredentials:true})
                .then((result)=>{
                    if(result.request.status === 200){
                        this.editedData = undefined;
                        this.loadPeopleData();
                    }
                });
            }else{
                this.$axios.post(this.dashboard.app.restUrl+"/records/"+this.type, data, {withCredentials:true})
                .then((result)=>{
                    if(result.request.status === 200){
                        this.editedData = undefined;
                        this.loadPeopleData();
                    }
                });
            }
        },

        deleteDataSet(id){
            let url = this.dashboard.app.restUrl+"/records/"+this.type+"/"+id;
            this.$axios.delete(url,{withCredentials:true})
            .then((result)=>{
                if(result.request.status === 200){
                    this.loadPeopleData();
                }
            })
        },

        cancelEdit(){
            this.editedData = undefined;
        }
    }
    
}
</script>