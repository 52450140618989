    <template>
    <v-container fluid class="ma-0 pa-0">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Camp Daten</v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-btn class="error" @click="$refs.delDialog.deleteDialog = true">
                            <v-icon>mdi-alert</v-icon>Campdaten {{dashboard.activeCamp}} löschen
                            <confirm-dialog
                                ref="delDialog"
                                icon="mdi-alert"
                                icon-color="error--text"
                                title="Camp-Daten wirklich löschen?"
                                text="Camp-Daten, Mitarbeiter- und Teilnehmerwerden-Stammdaten werden unwiderruflich gelöscht."
                                :max-width="400"
                                :onConfirm="deleteCamp"
                            />
                        </v-btn>
                        <v-row class="mt-2" v-if="dashboard.campData.camps">
                            <v-col v-for="(camp, index) of dashboard.campData.camps" :key="index">
                                <v-card >
                                    <div v-for="(prop, i) of camp" :key="i">
                                        <v-card-title class="mb-0 pb-0 justify-center" v-if="prop.key === 'name'">{{prop.value}}</v-card-title>
                                    </div>
                                    
                                    <v-card-text>
                                        <div v-for="(prop, i) of camp" :key="i" class="mb-3">
                                            <v-text-field 
                                                v-if="prop.key !== 'name'" 
                                                outlined dense hide-details="true" 
                                                :label="prop.label" 
                                                :type="prop.type" 
                                                v-model="prop.value"
                                                @change="saveSettings()"
                                            ></v-text-field>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <base-data 
            :dashboard="dashboard"
            :campSettings="campSettings"
            title="Mitarbeiter Stammdaten"
            dataKey="employees"
        />
        
        <base-data 
            :dashboard="dashboard"
            :campSettings="campSettings"
            title="Teilnehmer Stammdaten"
            dataKey="participants"
        />
    </v-container>
</template>

<script>

import BaseData from './BaseData.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
    name:"CampSettings",
    components:{
        BaseData,
        ConfirmDialog,
    },
    props:{
        dashboard:Object,
    },
    data(){
        return{
            campSettings:this,
            
        }
    },
    methods:{
        saveSettings(){
            let id = this.dashboard.campData.id;
            let data = JSON.parse(JSON.stringify({data:this.dashboard.campData}));
            console.log(data);
            this.$axios.put(this.dashboard.app.restUrl+"/records/camps/"+id,data ,{withCredentials:true})
            .then((result)=>{
                console.log(result);
            })
        },
        deleteCamp(){
            let id = this.dashboard.campData.id;
            this.$axios.delete(this.dashboard.app.restUrl+"/records/camps/"+id,{withCredentials:true})
            .then(async (result)=>{
                if(result.request.status === 200){
                    await this.dashboard.loadCampList();
                    this.dashboard.activeCamp = this.dashboard.camps[this.dashboard.camps.length-1];
                    this.dashboard.selectCamp();
                }
            })
        }

    }
    
}
</script>