<template>
  <v-app v-if="appReady">
      <top-bar
        ref="TopBar"
        :app="this"
      >
      </top-bar>

      <v-main id="body">
        <nav-bar
          :app="this"
          ref="NavBar"
        />
        
        
          <v-row class="ma-4" v-if="!this.userActivated && this.loggedIn" justify="center">
              <v-alert  elevation="1" type="error">Ihr Konto wurde noch nicht aktiviert. </v-alert>
          </v-row>

          <router-view v-if="this.userActivated || !this.loggedIn" :app="app" ></router-view>
      </v-main>
      <notification ref="Notification"></notification>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue';
import TopBar from './components/TopBar.vue';
import Notification from './components/Notifiaction.vue';



export default {
  name: 'App',

  components: {
    TopBar,
    NavBar,
    Notification,
  },
  created(){
    this.restUrl = window.location.protocol + '//'+window.location.hostname+"/restApi/api.php"
    this.checkIfLoggedIn();
  },

  data(){
    return{
      app:this,
      appReady:false,
      axios:this.$axios,
      restUrl:"http://localhost/restApi/api.php",
      // restUrl:"/restApi/api.php", 
      userData:{},
      loggedIn:false,
      userActivated:false,
      email:"",
      password:"",
      routes:[
          {title:'Dashboard', link:"/", icon:"mdi-monitor-dashboard"},
          {title:'Profil', link:"/", icon:"mdi-account"}
      ],
    }
  },
  methods:{
        checkIfLoggedIn(){
            this.$axios.get(this.app.restUrl+"/me/", {withCredentials:true})
            .then((result)=>{
                this.appReady = true;
                if (result.request.status === 200){
                  this.$axios.get(this.restUrl+"/records/users/"+result.data.id, {withCredentials:true})
                  .then((result)=>{
                    this.loggedIn = true;
                    if(result.request.status === 200){
                      this.userActivated = result.data.active == 1;
                      this.userData = {
                        id:result.data.id,
                        firstName:result.data.firstName,
                        lastName:result.data.lastName,
                        email:result.data.email,
                        role:result.data.role,
                      };
                    }
                  })
                  .catch(()=>{
                    this.loggedIn = true;
                  })
                }
            })
            .catch((error)=>{
                this.appReady = true;
                console.log(error);
                this.loggedIn = false;
                this.$router.replace({"name":"Login"});
            });
        },
        showNotification(message, timeout, type){
          if(timeout === undefined){timeout = 2000;}
          if(type === undefined){type = "success";}
          this.$refs.Notification.showNotification(message, timeout, type);
        }
  }



};
</script>

<style scoped>
  #body{
    background-color:rgb(250, 250, 250);
  }
</style>
