<template>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        {{title}}
                        <v-spacer/>
                        <v-btn elevation="2" @click="addNewProperty()" class="primary" x-small fab><v-icon>mdi-plus</v-icon></v-btn>
                    </v-card-title>
                        <v-divider/>
                    <v-card-text>
                        <h2>Filter</h2>
                        <v-combobox v-if="dashboard.campData[dataKey]" v-model="dashboard.campData[dataKey].filter" @change="campSettings.saveSettings()" dense multiple solo label="Filter">
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                @click="select"
                                @click:close="removeFilter(item)"
                                >
                                <strong>{{ item }}</strong>
                                </v-chip>
                            </template>
                        </v-combobox>

                        <h2>Personendaten</h2>
                        <div v-if="dashboard.campData[dataKey]">
                            <draggable v-model="dashboard.campData[dataKey].props" @change="campSettings.saveSettings()" handle=".drag-handle" group="data" @start="drag=true" @end="drag=false">
                                <div v-for="(item, index) in dashboard.campData[dataKey].props" :key="index" >
                                    <property-config
                                        :class="{'striped':index%2==0, 'grey lighten-2':item.type==='separator'}"
                                        :index="index"
                                        :campSettings="campSettings"
                                        :dataObjectParent="dashboard.campData[dataKey].props"
                                        :dataObject="item"
                                    />
                                </div>
                            </draggable>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
</template>

<script>
import PropertyConfig from './PropertyConfig.vue';
import draggable from 'vuedraggable'

export default {
    name:"BaseData",
    components:{
        PropertyConfig,
        draggable,
    },
    props:{
        title:String,
        dashboard:Object,
        dataKey:String,
        campSettings:Object,
    },
    methods:{
        removeFilter(item){
            this.dashboard.campData[this.dataKey].filter.splice(this.dashboard.campData[this.dataKey].filter.indexOf(item), 1);
            this.dashboard.campData[this.dataKey].filter = [...this.dashboard.campData[this.dataKey].filter];
        },
        addNewProperty(){
            let data = JSON.parse(JSON.stringify(this.dashboard.campData));
            console.log(data);
            data[this.dataKey].props.push({
                key:"",
                value:undefined,
                type:"string",
                requiered:false,
                visible:false,
                options:[],
                icon:'',
                iconColor:'',
            });
            this.dashboard.campData = JSON.parse(JSON.stringify(data));
            this.campSettings.saveSettings();
            
        },
    }

}
</script>

<style scoped>
    .striped {
        background-color: rgba(0, 0, 0, .02);
    }
</style>