import Vue from 'vue'
import VueRouter from 'vue-router'
import PageLogin from '@/pages/Login.vue'
import PageRegister from '@/pages/Register.vue'
import PageDashboard from '@/pages/Dashboard.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: PageDashboard,
    props: true,
    meta:{requieresAuth:true},
  },
  {
    path: '/login',
    name: 'Login',
    component: PageLogin,
    props: true,
  },
  {
    path: '/register',
    name: 'Register',
    component: PageRegister,
    props: true,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   axios.get(app.restUrl+"/me",{withCredentials:true})
//   .then((result)=>{
//     if(result.response.status === 200){
//       next();
//     }
//   })
//   .catch(()=>{
//     next("/login");
//   })
// })

export default router
