<template>
    <v-container flex>
        <v-row>
            <v-col class="pr-1">
                <v-text-field
                    v-model="peopleManager.peopleSearch"
                    prepend-inner-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                    solo
                    dense
                    clearable
                    class="mt-0 pt-0"
                ></v-text-field>
            </v-col>
            <v-col v-if="dashboard.campData[type]" class="pl-1"> 
                <v-combobox
                    label="Filter"
                    prepend-inner-icon="mdi-filter"
                    :items="dashboard.campData[type].filter"
                    v-model="peopleManager.peopleFilter"
                    hide-details
                    small-chips
                    solo
                    dense
                    multiple
                    clearable
                    class="mb-2"
                >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeFilter(item)"
                        >
                        <strong>{{ item }}</strong>
                        </v-chip>
                    </template>
                </v-combobox>
            </v-col>
        </v-row>
        
        <v-data-table
            :headers="getTableHeaders()"
            v-model="peopleManager.peopleSelectedRows"
            :items="peopleData"
            :mobile-breakpoint="0"
            loading-text="Daten werden geladen."
            :loading="peopleManager.loadingData"
            show-select
            dense
        >   

            <template v-slot:item="props">
                <tr :class="{'striped':props.index%2==0, 'blue lighten-5':props.index === expandedRow}" style="height:48px;">
                    <td><v-checkbox class="ma-0" hide-details="" :input-value="props.isSelected" @change="props.select($event)"></v-checkbox></td>
                    <td  v-for="(value, key) in props.item" :key="key">
                        
                        <div v-if="getPropType(key) === 'selection-multi'">
                            <v-chip-group >
                                <v-chip small v-for='tag in value' :key='tag'>{{tag}}</v-chip>
                            </v-chip-group>
                        </div>
                        <div v-else-if="getPropType(key) === 'rating'"><v-rating dense readonly color="silver" background-color="silver" :value="value" half-increments></v-rating></div>
                        <div v-else-if="getPropType(key) === 'checkbox'">
                            <v-checkbox v-if="getPropIcon(key) === undefined" readonly hide-details class="ma-0" :value="value"></v-checkbox>
                            <v-card width="24" height="24" :class="{'error':value==undefined || value == 0 || value == 'false', 'success':value== 1 || value == 'true'}" v-else>
                                <v-icon small class="ma-1 white--text ">{{getPropIcon(key)}}</v-icon>
                            </v-card>
                        </div>
                        
                        <div v-else>{{value}}</div>
                    </td>
                    
                    <td class="text-right" style="min-width:120px">
                        <v-btn 
                            v-if="expandedRow!==props.index"
                            :disabled="expandedRow>-1"
                            x-small
                            fab
                            class="elevation-2 mr-2"
                            color="primary"
                            @click="editPerson(props.item.id); expandedRow=props.index"
                        >
                            <v-icon>
                                mdi-pencil-outline
                            </v-icon>
                        </v-btn>
                        <v-btn 
                            
                            v-if="expandedRow===props.index"
                            x-small
                            fab
                            class="elevation-2 mr-2"
                            color="primary"
                            @click="expandedRow=-1; peopleManager.editedData=undefined"
                        >
                            <v-icon>
                                mdi-chevron-up
                            </v-icon>
                        </v-btn>
                        <v-btn 
                            :disabled="expandedRow>-1"
                            x-small
                            fab
                            class="elevation-2"
                            color="error"
                            @click="$refs.delDialog.show(); deleteId=props.item.id;"
                        >
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>

                <!-- Edit Person Row -->
                <tr style="height:0px; border:0px;">
                    <td colspan="100%" class="ma-0 pa-0" style="height:0px; border:0px;">
                        <v-expand-transition>
                            <div class="row-dark ma-0 pa-0" v-if="props.index === expandedRow && peopleManager.editedData">
                                <v-row class="ma-0 pa-0">
                                    <people-form-editor
                                        :people-manager="peopleManager"
                                        :onCancel="()=>{expandedRow=-1}"
                                        :onSave="()=>{expandedRow=-1}"
                                    />
                                </v-row>
                            </div>
                        </v-expand-transition>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <confirm-dialog 
            ref="delDialog"
            icon="mdi-alert"
            icon-color="error--text"
            title="Personendaten wirklich löschen?"
            text="Personendaten werden unwiderruflich gelöscht."
            :max-width="400"
            :onConfirm="deletePerson"
        />

    </v-container>
</template>

<script>

import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PeopleFormEditor from '@/components/PeopleFormEditor.vue';

export default {
    name:"PeopleTable",
    components:{
        ConfirmDialog,
        PeopleFormEditor,
    },
    props:{
        type:{type:String, default:"employees"},
        peopleManager:Object,
        dashboard:Object,
    },
    data(){
        return{
            expandedRow:-1,
            checkAll:false,
            deleteId:undefined,
        }
    },
    computed:{
        peopleData(){
            let filteredData = [];
            let allFilter = this.peopleManager.peopleFilter;
            if(this.peopleManager.peopleSearch != ""){
                allFilter = allFilter.concat([this.peopleManager.peopleSearch]);
            }

            if(allFilter.length === 0){
                return this.peopleManager.peopleData;
            }


            // loop through all persons in peopleData
            let i=-1;
            for(let person of this.peopleManager.peopleDataComplete){
                i++;

                // create passed filter for each selected filter
                var filterPassed = [];
                for(let filter of allFilter){
                    filter;
                    filterPassed.push(false)

                }

                // collect data of passed filter
                let j=-1;
                for(let filter of allFilter){
                    j++;
                    if(filter){
                        for(let propKey in person){
                            let propValue = person[propKey];
                            if(propValue){
                                if(String(propValue).toLowerCase().replace(/ /g,'').includes(filter.toLowerCase().replace(/ /g,''))){
                                    filterPassed[j] = true;
                                    continue;
                                }
                                
                            }
                        }
                    }else{
                        filterPassed[j] = true;
                    }
                }

                // if all filters passed, person is added to display
                let allFilterPassed=true;
                for(let passed of filterPassed){
                    if(passed == false){
                        allFilterPassed = false;
                        break
                    }
                }
                if(allFilterPassed){
                    filteredData.push(this.peopleManager.peopleData[i]);
                }
            }
            return filteredData;
        }
    },
    methods:{
        getPropType(key){
            return this.peopleManager.propertyTypes[key];
        },
        getPropIcon(key){
            return this.peopleManager.propertyIcons[key];
        },
        removeFilter(item){
            this.peopleManager.peopleFilter.splice(this.peopleManager.peopleFilter.indexOf(item), 1)
            this.peopleManager.peopleFilter = [...this.peopleManager.peopleFilter]
        },
        objectIsArray(ob){
            return Array.isArray(ob);
        },
        getTableHeaders(){
            var headers = []
            var foundKeys = []
            if(this.peopleManager.peopleData && this.peopleManager.peopleData.length > 0){
                for(let person of this.peopleManager.peopleData){
                    for(let key in person){
                        if(foundKeys.indexOf(key) === -1){
                            foundKeys.push(key)
                            let header = {
                                text: key,
                                align: 'start',
                                value: key,
                                sortable: true,
                                sort:'',
                            }
                            headers.push(header);
                        }
                    }
                }
                headers.push(
                    {
                        text: 'Aktionen',
                        align: 'end',
                        value: 'editUser',
                        sortable: false,
                    },
                )
            }
            return headers;
        },
        editPerson(id){
            this.peopleManager.editDataSet(id);
        },
        deletePerson(){
            this.peopleManager.deleteDataSet(this.deleteId);
        }
    }

}
</script>

<style scoped>
    .striped {
        background-color: rgba(0, 0, 0, .02);
    }

    .row-dark{
        background-color:rgb(241, 241, 241);
    }
</style>