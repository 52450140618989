<template>
    <v-container>   
    <v-row justify="center">
        <div style="width:100%; height:60px;"/>
        <v-card
            width="400"
        >
        <v-card-title >
            <v-icon>mdi-account</v-icon>
            Anmeldung
        </v-card-title>    
        <v-card-text class="text-center">
            <v-text-field @keydown.enter="login" v-model="email" label="Email" type="Login" solo>

            </v-text-field>

            <v-text-field 
                @keydown.enter="login" 
                v-model="password" 
                label="Passwort" 
                :type="showPassword ? 'text' : 'password'"
                solo
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
            >

            </v-text-field>

            <v-btn
                class="primary"
                @click="login()"
            >   
                Login
                <v-icon class="" small>mdi-login</v-icon>
            </v-btn>

        </v-card-text>
        <v-card-subtitle @click="register()" class="text-center m-0 p-0">
                <a>Registieren</a>
        </v-card-subtitle>
    </v-card>    
    </v-row>
    <v-row class="ma-2" align="center" justify="center">
            <v-snackbar v-model="warning" :timeout="warningTimeout" type="error">Login ist fehlgeschlagen</v-snackbar>
    </v-row>
    
    
    </v-container>
    
</template>

<script>

export default {
    name:"PageLogin",
    props:{
        app:Object
    },
    data(){
        return{
            email:"",
            password:"",
            showPassword:false,
            warning: false,
            warningTimeout: 2000,
        }
    },
    methods:{
        register(){
            if(this.$router.currentRoute.name !== 'Register'){
                this.$router.replace({'name':'Register'});
            }
        },
        login(){
            this.email = this.email.trim();

            this.$axios.post(this.app.restUrl+"/login",{username:this.email, password:this.password}, {withCredentials:true})
            .then((result)=>{
                if(result.request.status === 200){
                    this.app.loggedIn = true;
                    this.app.userActivated = result.data.active == 1
                    this.$router.replace({'name':'Dashboard'});
                }
                console.log(result);
            })
            .catch((error)=>{
                this.warning = true;
                console.log(error);
            });
        }
    }
}
</script>

<style scoped>

</style>