<template>
    <v-dialog :max-width="maxWidth" v-model="deleteDialog">
        <v-card>
            <v-card-title justify="center"><v-icon :class="iconColor">{{icon}}</v-icon>{{title}}</v-card-title>
            <v-card-text>{{text}}</v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn ref="delBtn" small @click="onConfirm(); hide();" class="primary">Ja</v-btn>
                <v-btn small @click="onCancel(); hide();">Nein</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name:"ConfirmDialog",
    props:{
        icon:{type:String, default:"mdi-information"},
        iconColor:{type:String, default:"primary--text"},
        title:{type:String, default:"Title"},
        text:{type:String, default:"Text"},
        onConfirm:{type:Function, default:()=>{}},
        onCancel:{type:Function, default:()=>{}},
        maxWidth:{type:Number, default:250}
    },
    data(){
        return{
            deleteDialog:false,
        }
    },
    methods:{
        show(){
            this.deleteDialog=true;
        },
        hide(){
            this.deleteDialog=false;
        }
    }
}
</script>