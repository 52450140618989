<template>
    <v-container>   
    <v-row justify="center">
        <div style="width:100%; height:60px;"/>
        
        <v-row v-if="this.registrationSuccess">
            <v-col justify="center" align="center">
                <v-alert type="success">Sie wurden erfolgreich registriert und werden in kürze weitergeleitet werden...</v-alert>
                <v-btn @click="backToLoginPage()">Zum Login</v-btn>
            </v-col>
        </v-row>
        
        <v-card
            width="400"
            v-if="!this.registrationSuccess"
        >
        <v-card-title >
            <v-icon>mdi-account</v-icon>
            Registrierung
        </v-card-title>    
        <v-card-text class="text-center">
            <v-text-field @keydown.enter="register" v-model="firstName" label="Vorname" solo />
            <v-text-field @keydown.enter="register" v-model="lastName" label="Nachname" solo />
            <v-text-field @keydown.enter="register" v-model="email" label="Email" type="Login" solo />

            <v-text-field 
                @keydown.enter="register" 
                v-model="password" 
                label="Passwort" 
                :type="showPassword ? 'text' : 'password'"
                solo
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
            >

            </v-text-field>

            <v-text-field 
                @keydown.enter="register" 
                v-model="passwordConfirm" 
                label="Confirm Passwort" 
                :type="showPassword ? 'text' : 'password'"
                solo
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
            >

            </v-text-field>

            <v-btn
                class="mr-2"
                @click="backToLoginPage()"
            >   
                <v-icon class="" small>mdi-arrow-left</v-icon>
                Zurück
            </v-btn>
            <v-btn
                class="primary"
                @click="register()"
            >   
                Registrieren
            </v-btn>

        </v-card-text>
    </v-card>    
    </v-row>
    
    
    </v-container>
    
</template>

<script>

export default {
    name:"PageRegister",
    props:{
        app:Object
    },
    data(){
        return{
            firstName:"",
            lastName:"",
            email:"",
            password:"",
            passwordConfirm:"",
            showPassword:false,
            registrationSuccess:false,
            redirectTimer:undefined,
        }
    },
    methods:{
        register(){
            let user = {
                username: this.email,
                password: this.password
                
            }

            if(this.password === this.passwordConfirm){
                this.$axios.post(this.app.restUrl+"/register/",user)
                .then((result)=>{
                    if(result.request.status === 200){
                    
                        this.$axios.post(this.app.restUrl+"/login",{username:this.email, password:this.password}, {withCredentials:true})
                        .then((result)=>{
                            if(result.request.status === 200){
                                let data = {firstName:this.firstName, lastName:this.lastName}
                                this.$axios.put(this.app.restUrl+"/records/users/"+result.data.id,data,{withCredentials:true})
                                .then((result)=>{
                                    if(result.request.status === 200){
                                        this.$axios.post(this.app.restUrl+"/logout",{},{withCredentials:true})
                                        this.registrationSuccess = true;
                                        this.redirectTimer = setTimeout(()=>{
                                            this.backToLoginPage();
                                        }, 4000);
                                    }
                                })
                            }
                        });


                    }
                })
                .catch((error)=>{
                    console.log(error);
                })
            }else{
                console.log("Passwords not not match");
            }
        },
        
        registerSuccess(){
            this.registrationSuccess = true;
        },
        
        backToLoginPage(){
            clearTimeout(this.redirectTimer);
            this.$router.replace({'name':'Login'});
        }
    }
}
</script>

<style scoped>

</style>