<template>
    <v-snackbar v-model="snackbar" :timeout="timeout">{{message}}</v-snackbar>
</template>

<script>
export default {
    data(){
        return{
            snackbar:false,
            timeout:2000,
            message:"",
            type:"success"
        }
    },
    methods:{
        showNotification(message, timeout, type){
            this.snackbar = true;
            this.message = message;
            this.timeout = timeout;
            this.type = type;
        }
    }

}
</script>