<template>
    <v-container class="mb-0 pb-0">
        <div v-if="title !== ''">
            <h2>{{title}}</h2>
            <v-divider/>
        </div>

        <div v-for="(sep, i) in peopleManager.editedData" :key="i" >
            <v-card v-if="sep.type === 'separator' || i === 0" elevation="0" class="mb-5">
                <v-card-title v-if="sep.type === 'separator'" class="mb-0 pb-0 pt-0">
                    <v-icon>{{sep.icon}}</v-icon>{{sep.key}}
                </v-card-title>
                <v-card-text clasS="mt-0 pt-0 pb-1 mr-2">
                    <div v-for="(item, index) of peopleManager.editedData" :key="index" class="ma-3">
                        <v-row v-if="index > getFirstSeparator(i) && index < getNextSeparator(i)">
                            <v-col align="right"  class="ma-0 pa-0 mr-3 col-1">
                                <div class="mt-3">
                                    <h4>{{item.key}}</h4>
                                </div>
                            </v-col>
                            <v-col class="ma-0 pa-0">
                                <v-text-field v-if="item.type === 'string' || item.type === 'number' && item.options.length === 0" class="mt-0" :type="item.type" :label="item.key" v-model="peopleManager.editedData[index].value" hide-details solo dense></v-text-field>
                                <v-select v-if="item.type === 'selection' || item.type === 'selection-multi' || item.options.length > 0" :multiple="item.type == 'selection-multi'" :items="item.options" solo dense hide-details :label="item.key" class="mt-0" v-model="peopleManager.editedData[index].value"></v-select>

                                <v-text-field v-if="item.type === 'date' || item.type === 'datetime-local'" class="mt-0" :type="item.type" :label="item.key" v-model="peopleManager.editedData[index].value" hide-details solo dense>
                                    <v-date-picker></v-date-picker>
                                </v-text-field>
                                
                                <v-textarea v-if="item.type === 'textarea'" v-model="peopleManager.editedData[index].value" solo></v-textarea>
                                <v-rating half-increments v-if="item.type ==='rating'" v-model="peopleManager.editedData[index].value"></v-rating>
                                
                                <v-checkbox v-if="item.type === 'checkbox'" false-value="false" true-value="true" hide-details="" class="mt-2" :value="peopleManager.editedData[index].value" v-model="peopleManager.editedData[index].value"></v-checkbox>

                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <v-divider/>
        <v-row class="mb-2">
            <v-spacer/>
            <v-btn class="mr-2 mt-2 mb-0 primary" @click="peopleManager.saveEdit(); onSave();">Speichern</v-btn>
            <v-btn class="mr-2 mt-2 mb-0 error" @click="peopleManager.cancelEdit(); onCancel();">Abrrechen</v-btn>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name:"PeopleFormEditor",
    props:{
        peopleManager:Object,
        onSave:{type:Function, default:()=>{}},
        onCancel:{type:Function, default:()=>{}},
        disabled:{type:Boolean, default:false},
    },
    data(){
        return{
            title:""
        }
    },
    methods:{
        getFirstSeparator(index){
            let i = -1
            for(let prop of this.peopleManager.editedData){
                i++;
                if(prop.type === "separator" && i === index){
                    return i;
                }
            }
            return -1;
        },
        getNextSeparator(index){
            let i = -1
            for(let prop of this.peopleManager.editedData){
                i++;
                if(prop.type === "separator" && i > index){
                    return i;
                }
            }
            return Infinity;
        }
    }
}
</script>