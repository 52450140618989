<template>
    <v-container>
        <v-menu offset-y v-if="this.app.loggedIn" :close-on-content-click="closeOnContentClick">
                <template v-slot:activator="{ on, attrs }">
                    
                    <v-btn 
                    fab
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on = "on"
                    @click="loadUserData()"
                    >
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="closeOnContentClick=false">
                        <v-btn fab x-small class="mr-2" elevation="1"><v-icon>mdi-account</v-icon></v-btn>
                        {{this.userData.firstName}} {{this.userData.lastName}}
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                        dense
                        v-for="(item, index) in items"
                        :key="index"
                        @click="item.function()"
                    >
                        <v-icon>{{ item.icon }}</v-icon><v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            

            <v-dialog
                v-model="dialog"
                width="500"
                persistent
            >
        
                <v-card>
                <v-card-title class="headline ma-0">
                    Konto Einstellungen
                    <v-spacer/>
                    <v-btn
                    class="ma-0 pa-0"
                    fab
                    color="error"
                    text
                    @click="closeDialog()"
                    >
                    <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                </v-card-title>
        
                <v-card-text>
                    <v-row v-for="(value, key) in this.userData" :key="key">
                        <v-text-field v-if='key !== "id" && key !== "role"' :label="getLabel(key)" v-model="userData[key]" />
                        <v-select v-if='key === "role"' :disabled="app.userData['role']!=='Admin'" :items="['Normal','Admin']" v-model="userData[key]" :label="getLabel(key)"></v-select>
                    </v-row>
                    <v-row>
                        <v-btn v-if="!changePassword" @click="changePassword=!changePassword">Passwort ändern</v-btn>
                    </v-row>
                        
                    <v-row>
                        <v-text-field 
                            v-if="changePassword"
                            :label="'Passwort'"
                            :type="showPassword ? 'text' : 'password'" 
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            v-model="password"
                        ></v-text-field>
                    </v-row>
                        
                    <v-row>
                        <v-text-field 
                            v-if="changePassword"
                            :label="'Neues Passwort'"
                            :type="showPassword ? 'text' : 'password'" 
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            v-model="newPassword"
                        ></v-text-field>
                    </v-row>
                        
                    <v-row>
                        <v-text-field 
                            v-if="changePassword"
                            :label="'Passwort bestätigen'"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            v-model="passwordConfirmed"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-btn class="primary" v-if="changePassword" @click="sendNewPassword()">Ändern</v-btn>
                    </v-row>
                    
                    

                </v-card-text>
        
                <v-divider></v-divider>
        
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="primary"
                    @click="saveUserData()"
                    >
                    Speichern
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
</template>

<script>
export default {
    name:"UserMenu",
    props:{
        app:Object
    },
    created(){
        this.items=[
            {
                title:"Konto Einstellungen",
                icon:"mdi-account-edit",
                function:()=>{this.showDialog();this.closeOnContentClick = true;}
            },
            {
                title:"Dashboard Benutzer",
                icon:"mdi-account-group",
                function:()=>{this.closeOnContentClick = false;}
            },
            {
                title:"Logout",
                icon:"mdi-logout",
                function:this.logout
            },
        ]
    },
    data: ()=>({
        items:[],
        dialog:false,
        userData:{},
        closeOnContentClick:false,
        showPassword:false,
        changePassword:false,
        password:"",
        newPassword:"",
        passwordConfirmed:"",
        labels:{
            "firstName":"Vorname",
            "lastName":"Nachname",
            "email":"Email",
            "role":"Rolle"
        }
    }),
    methods:{


        logout(){
            this.$axios.post(this.app.restUrl+"/logout", {},{withCredentials:true})
            .then((result)=>{
                if(result.request.status === 200){
                    this.app.loggedIn = false;
                    this.$router.replace({"name":"Login"});
                }
            })
        },
        closeDialog(){
            this.dialog=false;
            this.changePassword=false;
        },
        loadUserData(){
            this.userData = JSON.parse(JSON.stringify(this.app.userData));
        },
        showDialog(){
            this.loadUserData();
            this.dialog=true;
            this.changePassword = false;
        },
        saveUserData(){
            this.$axios.put(this.app.restUrl+"/records/users/"+this.userData.id, this.userData, {withCredentials:true})
            .then((result)=>{
                if(result.request.status === 200){
                    this.showSaveSnackbar = true;
                    this.app.userData = JSON.parse(JSON.stringify(this.userData));
                    this.dialog = false;
                    this.app.showNotification("Konfiguration wurde gespeichert.")
                    console.log("Save successful")
                }
            })
        },
        sendNewPassword(){
            if(this.newPassword === this.passwordConfirmed && this.newPassword.length >= 4){
                let data = {
                    username:this.userData.email,
                    password:this.password,
                    newPassword:this.newPassword,
                }
                console.log(data);
                this.$axios.post(this.app.restUrl+"/password",data,{withCredentials:true})
                .then((result)=>{
                    if(result.request.status === 200){
                        this.app.showNotification("Ihr Passwort wurde erfolgreich geändert.");
                        this.changePassword = false;
                        this.passwordConfirmed = "";
                        this.password = "";
                        this.newPassword = "";
                    }
                })
                .catch(()=>{
                    this.app.showNotification("Ihr Passwort stimmt nicht überein.");
                })
            }else if(this.newPassword !== this.passwordConfirmed){
                this.app.showNotification("Ihr Passwort stimmt nicht überein.");
            }
        },
        getLabel(key){
            if(this.labels[key]){
                return this.labels[key];
            }
            return key;
        }

    }

}
</script>