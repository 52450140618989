<template>
    <v-app-bar
      app
      class="gradient"
      dark
    >
        <!-- <v-app-bar-nav-icon @click="app.$refs.NavBar.drawer = true"></v-app-bar-nav-icon> -->
      <v-toolbar-title>
          <span class="mr-2">
            <v-icon>mdi-tablet-dashboard</v-icon>
        </span>Dashboard</v-toolbar-title>

      <v-spacer></v-spacer>
      
      <div class="d-flex align-center">
          
        <v-img
          alt="Espelcamp Logo"
          class="shrink mr-2"
          contain
          src="@/assets/espelcamp_logo.png"
          transition="scale-transition"
          width="80"
        />
      </div>
      <v-spacer></v-spacer>
      <div class="text-right" style="width:140px;">
        <user-menu :app="this.app"/>
      </div>
    </v-app-bar>
</template>

<script>

import UserMenu from './UserMenu.vue';

export default {
    name:"TopBar",
    components:{
        UserMenu,
    },
    props:{
        app:Object,
    },
    data(){
        return{
        }
    },
    methods:{
    }
}
</script>

<style scoped>
  .gradient{
      background: linear-gradient(90deg, rgba(69,90,100,1) 0%, rgba(38,50,56,1) 100%);
  }

</style>