<template>
    <v-container fluid class="border-bottom ma-0 pa-0 prop-item">        
        <v-row id="input-usage">
            <v-col md="auto pa-1 pl-3 pr-3" >
                <div class="drag-handle  mt-2">
                    <v-icon>mdi-drag-vertical</v-icon>
                    <span class="mr-2">{{index + 1}}</span>
                </div>
            </v-col>
            <v-row md="auto" class="pa-1 pt-3">
                <div style="width:40px;">
                    <v-icon>{{dataObject.icon}}</v-icon>
                </div>
                <div style="min-width:150px;">
                        {{dataObject.key}}
                </div>
                <div style="min-width:150px;">
                        {{getPropTypeName(dataObject.type)}}
                </div>
                <!-- {{dataObject.key}}
                {{dataObject.type}} -->
            </v-row>
            
            <v-col>
                <v-spacer/>
            </v-col>
            <v-col md="auto" class="pa-1 pr-4">
                <v-btn elevation="2" @click="editProp = !editProp" class="primary mt-1  ml-1" x-small fab><v-icon>mdi-pencil-outline</v-icon></v-btn>
                <v-btn elevation="2" @click="$refs.delEntryDialog.show()" class="error mt-1  ml-1" x-small fab><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
        </v-row>

        <!-- Edit Property Row -->
        <v-expand-transition>
            <div v-if="editProp">
                <v-row class="ma-0 row-dark col-12">
                    <v-col align="right" md="auto" class="pt-5">
                        <h3 class="caption-prop mb-2">Bezeichnung</h3>
                        <h3 class="caption-prop mb-2">Standard Wert</h3>
                        <h3 class="caption-prop mb-2">Typ</h3>
                        <h3 class="caption-prop mb-2">Icon</h3>
                        <h3 class="caption-prop mb-2">Optionen</h3>
                        <h3 class="caption-prop mb-2">Erforderlich</h3>
                        <h3 class="caption-prop mb-2">Sichtbar</h3>
                    </v-col>
                    <v-col>
                        <v-text-field @change="saveData()" hide-details="true" v-model="dataObject.key" class="mr-1 mb-2 fields" label="Bezeichnung" dense solo></v-text-field>
                        <v-text-field v-if="dataObject.type !== 'selection'" @change="saveData()" hide-details="true" v-model="dataObject.value" :type="dataObject.type" class="mr-1 mb-2 fields" label="Standard Wert" dense solo></v-text-field>
                        <v-select v-if="dataObject.type === 'selection'" @change="saveData()" hide-details="true" :items="dataObject.options" v-model="dataObject.value" :type="dataObject.type" class="mr-1 mb-2 fields" label="Standard Wert" dense solo></v-select>
                        <v-select @change="saveData()" hide-details="true" v-model="dataObject.type" class="mr-1 mb-2 fields" item-text="key" item-value="value" :items="propKeys" label="Type" dense solo />
                        
                        <v-select @change="saveData()" class="mb-2 mr-1" hide-details="true" solo dense :prepend-inner-icon="dataObject.icon" :items="icons" v-model="dataObject.icon" >
                            <template slot="selection" slot-scope="data">
                                <v-icon>{{data.item.name}}</v-icon>
                            </template>
                            <template slot="item" slot-scope="data">
                                <v-icon>{{data.item}}</v-icon>
                            </template>
                        </v-select>
                        
                        <v-combobox @change="saveData()" hide-details="true" v-model="dataObject.options" small-chips multiple class="mb-2 mr-1 fields" label="Optionen" dense solo>
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip
                                v-bind="attrs"
                                close
                                :input-value="selected"
                                @click="select"
                                @click:close="remove(item)"
                                >
                                <strong>{{ item }}</strong>&nbsp;
                                </v-chip>
                            </template>

                        </v-combobox>
                        <v-switch @change="saveData()" hide-details="true" class="mb-2"  v-model="dataObject.requiered"></v-switch>
                        <v-switch @change="saveData()" hide-details="true" class="mb-2"  v-model="dataObject.visible"></v-switch>

                    </v-col>
                </v-row>
            </div>
        </v-expand-transition>
    
        <confirm-dialog 
            ref="delEntryDialog"
            icon="mdi-alert"
            icon-color="error--text"
            title = "Eintrag löschen?"
            text="Eintrag wird dauerhaft gelöscht."
            :onConfirm="deleteProperty"
        />

    </v-container>

</template>

<script>

import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
    name:"PropertyConfig",
    components:{
        ConfirmDialog
    },
    props:{
        index:Number,
        dataObjectParent:Array,
        dataObject:Object,
        campSettings:Object,
    },
    data(){
        return{
            editProp:false,
            visiblePropValues:[
                "key","type"
            ],
            propKeys:[
                {key:"Zahl",value:"number"}, 
                {key:"Zeichenfolge",value:"string"}, 
                {key:"Textfeld",value:"textarea"}, 
                {key:"Datum",value:"date"}, 
                {key:"Datum/Zeit",value:"datetime-local"}, 
                {key:"Auswahl",value:"selection"},
                {key:"Multi-Auswahl",value:"selection-multi"},
                {key:"Bewertung",value:"rating"},
                {key:"Checkbox", value:"checkbox"},
                {key:"Separator", value:"separator"}
            ],

            icons:[
                "mdi-note",
                "mdi-currency-eur",
                "mdi-account-star",
                "mdi-account",
                "mdi-heart",
                "mdi-star",
                "mdi-list-status",
                "mdi-tent",
            ]
        }
    },
    methods:{
        getPropTypeName(key){
            for(let prop of this.propKeys){
                if(prop.value === key){
                    return prop.key;
                }
            }
            return key
        },
        remove(item){
            this.dataObject.options.splice(this.dataObject.options.indexOf(item),1)
            this.dataObject.options = [...this.dataObject.options]
        },
        deleteProperty(){
            this.dataObjectParent.splice(this.dataObjectParent.indexOf(this.dataObject),1);
            this.saveData();
        },
        moveItem(dir){
            let index = this.dataObjectParent.indexOf(this.dataObject)
            let item = this.dataObjectParent[index];
            this.dataObjectParent.splice(index,1);
            this.dataObjectParent.splice(index+dir, 0, item);
        },
        saveData(){
            this.campSettings.saveSettings();
        },
    }

}
</script>

<style scoped>
    .fields{
        /* width:50px; */
    }

    .row-dark{
        background-color:rgb(241, 241, 241);
    }

    .border-bottom{
        border-bottom:1px solid silver;
    }

    .caption-prop{
        height:39px;
    }
</style>