<template>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
      >
        <v-list
          dense
          rounded
          
        >
          <v-list-item-group
            v-model="group"
          >
            <v-list-item 
              v-for="(item, index) in app.routes" :key="index"
              :to="item.link"
            >
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.title}}
              </v-list-item-title>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
</template>

<script>
export default {
    name:"NavBar",
    props:{
      app:Object
    },
    data(){
      return{
        drawer:false,
        group:null
      }
    }
}
</script>

<style scoped>

</style>