<template>
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          
            <v-btn 
                x-small
                fab
                dark
                class="elevation-2 mr-2"
                color="primary"
                
                v-bind="attrs"
                v-on="on"
            >
                <v-icon> mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Freizeit - Neuer Datensatz</span>
          </v-card-title>
          <v-card-text>

              <v-container>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Jahr"
                            v-model="year"
                            :rules="rules"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>

              </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="this.createNewCampDataSet"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 
</template>

<script>
export default {
    name:"NewCampDialog",
    props:{
      app:Object,
      dashboard:Object,
    },
    data: () => ({
        rules: [
        value => !!value || 'Benötigt.',
        value => !isNaN(parseInt(value)) || 'Bitte eine Zahl eintragen',
        ],
        year:undefined,
        dialog: false,
    }),
    methods:{
        createNewCamp(name){
          let camp = [
              {key:'name', value:name, type:'string', label:'Name'},
              {key:'ageFrom', value:null, type:'date', label:'Teilnehmer-Alter von'},
              {key:'ageTo', value:null, type:'date', label:'Teilnehmer-Alter bis'},
              {key:'dateFrom', value:null, type:'date', label:'Freizeit-Datum von'},
              {key:'dateTo', value:null, type:'date', label:'Freizeit-Datum bis'},
              {key:'participantCount', value:null, type:'number', label:'Teilnehmerzahl'},
            ]
            return camp;
        },

        createNewCampDataSet(){

            let campData = {
              data:{
                year:this.year,
                camps:[this.createNewCamp("E1"),this.createNewCamp("E2"), this.createNewCamp("E3")],
                participants:{
                  props:[],
                  filter:[],
                },
                employees:{
                  props:[],
                  filter:[],
                }
              }
            }

            this.$axios.post(this.app.restUrl+"/records/camps",campData,{withCredentials:true})
            .then((result)=>{
              if(result.request.status === 200){
                this.app.showNotification("Neuer Camp Datensatz wurde erstellt.")
                this.dashboard.loadCampList()
                this.dashboard.activeCamp = this.year;
                this.dialog = false;
                this.year = undefined;
              }
            })
        },
    }
}
</script>

<style scoped>

</style>